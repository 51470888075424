import {ReactNode, useState} from 'react'
import {useDebounce} from 'react-use'
import styled from '@emotion/styled'

import {Flex, Box} from 'src/components/Box'
import {Action} from 'src/components/Action'
import SearchInput from 'src/components/form/SearchInput'

type GetItemProps<Item> = (params: {item: Item; key: string | number}) => {
  key: string | number
  onClick: () => void
}

export interface RenderProps<Item> {
  searchValue: string
  getItemProps: GetItemProps<Item>
}

interface Props<Item> {
  placeholder: string
  disabled?: boolean
  onSelect: (item: Item) => void
  children: (renderProps: RenderProps<Item>) => ReactNode
  initialSearchValue?: string
  id: string
}

const ListContainer = styled.ul(({theme}) => ({
  background: theme.colors.white,
  display: 'grid',
  gridRowGap: theme.space[3],
  paddingTop: theme.space[2],
  paddingBottom: theme.space[2],
  margin: 0,
  overflow: 'auto',
  flexGrow: 1,
  gridAutoRows: 'max-content',
  borderTop: `1px solid ${theme.colors.grey2}`,
}))

export const SearchResultItem = styled(Action)({
  textAlign: 'left',
})

SearchResultItem.defaultProps = {
  role: 'listitem',
}

const Search = <T extends any>({
  placeholder,
  onSelect,
  children,
  disabled = false,
  initialSearchValue = '',
  id,
}: Props<T>) => {
  const [search, setSearch] = useState(initialSearchValue)
  const [debouncedSearch, setDebouncedSearch] = useState(initialSearchValue)

  useDebounce(
    () => {
      setDebouncedSearch(search)
    },
    200,
    [search]
  )

  const getItemProps: GetItemProps<T> = ({item, key}) => ({
    key,
    onClick: () => onSelect(item),
  })

  return (
    <Flex flexDirection="column" overflow="hidden" role="search">
      <Box px="2" pb="1">
        <SearchInput
          name={id}
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </Box>
      <ListContainer>
        {children({
          getItemProps,
          searchValue: debouncedSearch,
        })}
      </ListContainer>
    </Flex>
  )
}

export default Search
