import {useState} from 'react'
import compact from 'lodash/compact'

import useStateOptions from 'src/hooks/useStateOptions'
import Search, {SearchResultItem, RenderProps} from 'src/components/Search'
import {
  useSearchSchoolQuery,
  School,
  Maybe,
  State,
  StateOption,
} from 'src/graphql-generated'
import {Flex, Grid, Box} from 'src/components/Box'
import {Body, SmallBody} from 'src/components/text'
import Button from 'src/components/Button'

import {Action} from './Action'
import {CaretRightIcon, CaretLeftIcon} from './icons'
import Loading from './Loading'

type SchoolItem = Pick<School, 'id' | 'name' | 'city' | 'state'>

const SearchResults: React.FunctionComponent<
  RenderProps<SchoolItem> & {
    state: Maybe<State>
  }
> = ({searchValue, getItemProps, state}) => {
  const {data, loading, error} = useSearchSchoolQuery({
    variables: {
      filter: {
        name: {
          cont: searchValue,
        },
        state: {
          eq: state,
        },
      },
      limit: 50,
    },
  })

  if (loading) {
    return <Loading size="small" mt="4" />
  }

  if (error) {
    return <Box px="2">Something went wrong...</Box>
  }

  const schools = data && data.schools ? compact(data.schools.nodes) : []

  if (schools.length === 0) {
    return <Box px="2">No Search Results</Box>
  }

  return (
    <>
      {schools.map((school) => (
        <SearchResultItem {...getItemProps({key: school.id, item: school})}>
          <Flex
            css={(theme) => ({
              '&:hover': {
                backgroundColor: theme.colors.grey1,
              },
            })}
            px="2"
            flexDirection="column"
          >
            {school.name}
            <SmallBody color="grey4">
              {school.city}, {school.state}
            </SmallBody>
          </Flex>
        </SearchResultItem>
      ))}
      {data && data.schools.pageInfo.hasNextPage && (
        <Flex flexDirection="column" px="2">
          <SmallBody>Too many results to be displayed.</SmallBody>
          <SmallBody>Please refine your search.</SmallBody>
        </Flex>
      )}
    </>
  )
}

interface Props {
  onSelect: (school: SchoolItem) => void
  onCancel: () => void
}

const StateItem: React.FunctionComponent<{
  state: Pick<StateOption, 'code' | 'name'>
  onSelect: (state: Pick<StateOption, 'code' | 'name'>) => void
}> = ({state, onSelect}) => (
  <Action onClick={() => onSelect(state)}>
    <Grid px="2" gridTemplateColumns="1fr auto">
      <Body textAlign="left">{state.name}</Body>
      <Flex alignItems="center">
        <CaretRightIcon />
      </Flex>
    </Grid>
  </Action>
)

const SchoolPicker = ({onCancel, onSelect}: Props) => {
  const [state, setState] = useState<{code: State; name: string} | undefined>()
  const states = useStateOptions()

  const onStateSelect = (state: {code: State; name: string}) => setState(state)

  return (
    <>
      {!state && (
        <Grid width="100%" height="100%" gridTemplateRows="auto 1fr auto">
          <Flex p="2">
            <Body fontWeight="semiBold">What state is your school in?</Body>
          </Flex>
          <Grid gridGap="4" overflow="auto">
            {states.map((s) => (
              <StateItem state={s} onSelect={onStateSelect} key={s.code} />
            ))}
          </Grid>
          <Flex
            borderTop="1px solid"
            borderColor="grey2"
            justifyContent="center"
            alignItems="center"
            py="4"
          >
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Flex>
        </Grid>
      )}

      {state && (
        <Grid width="100%" height="100%" gridTemplateRows="auto 1fr">
          <Action onClick={() => setState(undefined)}>
            <Flex p="2" alignItems="baseline" justifyContent="flex-start">
              <CaretLeftIcon />
              <Body fontWeight="semiBold">Schools in {state.name}</Body>
            </Flex>
          </Action>

          <Search<SchoolItem>
            placeholder="Search by school name..."
            id="school-search"
            onSelect={onSelect}
          >
            {(props) => <SearchResults {...props} state={state.code} />}
          </Search>
        </Grid>
      )}
    </>
  )
}

export default SchoolPicker
