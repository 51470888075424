import React from 'react'

import {Body} from './text'
import ToggleField from './form/ToggleField'
import {Flex} from './Box'

const ProfilePreferences = () => {
  return (
    <>
      <Flex mb="1">
        <ToggleField label="Email" name="wantsEmailMessages" />
      </Flex>
      <Body color="grey5">
        Keep me updated on resources, scholarships, and campaigns from Get
        Schooled.
      </Body>
    </>
  )
}

export default ProfilePreferences
