import ReactModal, {Props as ReactModalProps} from 'react-modal'
import {useEffect} from 'react'

import {theme, zIndexes, vhHeight, cssCalc} from 'src/styles'
import {Flex} from 'src/components/Box'

ReactModal.setAppElement('#___gatsby')

export const maxModalHeight = cssCalc(vhHeight(100), '-', theme.space[4])
const modalWidth = cssCalc('100%', '-', theme.space[4])
export const maxModalWidth = '450px'

export interface ModalProps
  extends Pick<
    ReactModalProps,
    | 'isOpen'
    | 'onRequestClose'
    | 'closeTimeoutMS'
    | 'onAfterOpen'
    | 'shouldCloseOnEsc'
    | 'shouldCloseOnOverlayClick'
    | 'shouldFocusAfterRender'
    | 'shouldReturnFocusAfterClose'
  > {
  allowScroll?: boolean
  children?: React.ReactNode
}

const overlayStyle = ({allowScroll}: Pick<ModalProps, 'allowScroll'>) => ({
  maxWidth: '100vw',
  backgroundColor: 'rgba(2, 21, 70, 0.15)',
  zIndex: zIndexes.modal,
  overflow: allowScroll ? 'auto' : 'hidden',
  height: vhHeight(100),
  contain: 'strict',
})

const contentStyle = ({allowScroll}: Pick<ModalProps, 'allowScroll'>) => ({
  top: allowScroll ? 0 : '50%',
  left: '50%',
  right: 'auto',
  bottom: allowScroll ? '2rem' : 'auto',
  transform: allowScroll ? 'translateX(-50%)' : 'translate(-50%, -50%)',
  border: 'none',
  borderRadius: allowScroll ? 'none' : '4px',
  background: allowScroll ? 'none' : theme.colors.white,
  width: modalWidth,
  maxWidth: maxModalWidth,
  overflow: 'unset',
  padding: 0,
})

const Modal = ({children, allowScroll = false, ...props}: ModalProps) => {
  useEffect(() => {
    if (!props.isOpen) {
      return
    }
    const initialRootOverflow = document.documentElement.style.overflow
    document.documentElement.style.overflow = 'hidden'

    return () => {
      document.documentElement.style.overflow = initialRootOverflow
    }
  }, [allowScroll, props.isOpen])

  return (
    <ReactModal
      {...props}
      style={{
        overlay: overlayStyle({allowScroll}),
        content: contentStyle({allowScroll}),
      }}
    >
      <Flex width="100%">{children}</Flex>
    </ReactModal>
  )
}

export default Modal
