import {useField, UseFieldConfig} from 'react-final-form'

import ToggleInput from '../ToggleInput'
import FieldError from './FieldError'
import {Flex} from '../Box'
import {Lede} from '../text'

interface Props extends UseFieldConfig<string> {
  name: string
  disabled?: boolean
  type?: 'checkbox'
  label: string
}

const ToggleField = ({
  name,
  type = 'checkbox',
  disabled = false,
  label,
  ...config
}: Props) => {
  const {input} = useField<string, HTMLInputElement>(name, {
    type,
    ...config,
  })

  const labelId = `${name}-label`
  return (
    <Flex width="100%" flexDirection="column">
      <Flex justifyContent="space-between">
        <Lede id={labelId}>{label}</Lede>
        <ToggleInput
          {...input}
          aria-labelledby={labelId}
          type={type}
          disabled={disabled}
        />
      </Flex>
      <FieldError name={name} />
    </Flex>
  )
}

export default ToggleField
