import React, {useEffect, useState} from 'react'
import {toast, ToastContainer} from 'react-toastify'

import {NounInterviewIcon, GroupIcon, DownloadIcon} from 'src/components/icons'
import {useInterviewCoachReportCompletedQuery} from 'src/graphql-generated'
import {downloadReport, formatDate} from 'src/lib/util'

import {Box, Flex, Grid} from './Box'
import Loading from './Loading'
import 'react-toastify/dist/ReactToastify.css'

const InterviewCoach = () => {
  const {data: reportCountQuery} = useInterviewCoachReportCompletedQuery()
  const [interviewsCompleted, setInterviewsCompleted] = useState(0)

  const handleDownload = async () => {
    const result = await downloadReport()

    if (result.success) {
      toast.success('Report downloaded successfully!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    } else {
      toast.error(`Error: ${result.message}`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }

  useEffect(() => {
    if (reportCountQuery) {
      setInterviewsCompleted(
        reportCountQuery.interviewCoachReportCompleted.interviewsCompleted || 0
      )
    }
  }, [reportCountQuery])

  return reportCountQuery ? (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Flex color="grey5">
        <Box mt={3} color="#021546">
          <NounInterviewIcon mr={3} /># of Interviews Completed:{' '}
          {interviewsCompleted}
        </Box>
      </Flex>
      {interviewsCompleted !== 0 && (
        <Flex mt={3} color="#021546" alignItems="center">
          <GroupIcon mt={-4} mr={3} />
          <Grid>
            <span>Latest Interview Coach Report</span>
            <span>
              {reportCountQuery &&
                formatDate(reportCountQuery.interviewCoachReportCompleted.date)}
              <DownloadIcon onClick={handleDownload} cursor="pointer" ml={3} />
            </span>
          </Grid>
        </Flex>
      )}
    </>
  ) : (
    <Loading size="medium" />
  )
}

export default InterviewCoach
