import {
  useState,
  forwardRef,
  DetailedHTMLProps,
  InputHTMLAttributes,
  LabelHTMLAttributes,
} from 'react'
import {useField, UseFieldConfig} from 'react-final-form'

import {identity} from 'src/form/parse'
import {Box} from 'src/components/Box'

import {TextInput, Label, ErrorMessage} from './input'
import {hasFieldError} from './TextField'

export interface Props extends UseFieldConfig<string> {
  label: string
  name: string
  id?: string
  type?: 'text' | 'email' | 'password'
  autoFocus?: boolean
  readOnly?: boolean
}

interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  type: 'text' | 'email' | 'password' | 'number' | 'tel'
}

export interface BasicRoundedTextFieldProps {
  inputProps: InputProps
  labelProps: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >
  label: string
  error?: boolean
  errorMessage?: React.ReactNode
  linkify?: boolean
}

export const BasicRoundedTextField = forwardRef<
  HTMLInputElement,
  BasicRoundedTextFieldProps
>(
  (
    {
      label,
      error = false,
      errorMessage,
      linkify = false,
      inputProps,
      labelProps,
    },
    ref
  ) => {
    const [hasFocus, setHasFocus] = useState(false)

    const borderColor = error
      ? 'red'
      : hasFocus && !inputProps.readOnly
      ? 'caribbeanGreen'
      : 'grey2'

    return (
      <Box
        border="2px solid"
        borderColor={borderColor}
        borderRadius="15px"
        px="16px"
      >
        <Box px="2px">
          <Label {...labelProps}>
            {label}
            {error && errorMessage ? ' — ' : null}
          </Label>
          {error && errorMessage ? errorMessage : null}
        </Box>
        <TextInput
          {...inputProps}
          ref={ref}
          error={error}
          underlined={false}
          linkify={linkify}
          css={{paddingTop: '2px', paddingLeft: 0}}
          onFocus={(event) => {
            setHasFocus(true)
            inputProps.onFocus && inputProps.onFocus(event)
          }}
          onBlur={(event) => {
            setHasFocus(false)
            inputProps.onBlur && inputProps.onBlur(event)
          }}
        />
      </Box>
    )
  }
)

const RoundedTextField = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      name,
      id,
      type = 'text',
      parse = identity,
      autoFocus = false,
      readOnly = false,
      ...config
    },
    ref
  ) => {
    const {input, meta} = useField<string, HTMLInputElement>(name, {
      parse,
      ...config,
    })

    const showError = !readOnly && hasFieldError(meta)
    const inputId = id || name

    return (
      <BasicRoundedTextField
        ref={ref}
        error={showError}
        errorMessage={
          showError ? (
            <ErrorMessage>{meta.error || meta.submitError}</ErrorMessage>
          ) : null
        }
        label={label}
        inputProps={{
          ...input,
          type,
          readOnly,
          autoFocus,
          id: inputId,
        }}
        labelProps={{htmlFor: inputId}}
      />
    )
  }
)

export default RoundedTextField
