import {useState, useEffect} from 'react'
import {useField, UseFieldConfig, useFormState} from 'react-final-form'
import get from 'lodash/get'

import {PositionContainer, Flex} from 'src/components/Box'
import Modal, {maxModalHeight, maxModalWidth} from 'src/components/Modal'
import SchoolPicker from 'src/components/SchoolPicker'
import {School, Maybe} from 'src/graphql-generated'
import {Action} from 'src/components/Action'

import {BasicRoundedTextField} from './RoundedTextField'

export type SchoolItem = Pick<School, 'id' | 'name' | 'city' | 'state'>

interface Props extends UseFieldConfig<Maybe<SchoolItem>> {
  name: string
  label: string
  onChange?: (newValue: Maybe<SchoolItem>) => void
}

const SchoolField = ({name, label, onChange, ...config}: Props) => {
  const [showSearch, setShowSearch] = useState(false)
  const {input} = useField<Maybe<SchoolItem>, HTMLInputElement>(name, config)
  const formState = useFormState({subscription: {initialValues: true}})

  useEffect(() => {
    if (!onChange) {
      return
    }

    if (get(formState.initialValues[name], 'id') !== get(input.value, 'id')) {
      onChange(input.value)
    }
  }, [formState.initialValues, input.value, name, onChange])

  return (
    <>
      <Modal isOpen={showSearch} onRequestClose={() => setShowSearch(false)}>
        <Flex width={maxModalWidth} height={maxModalHeight}>
          <SchoolPicker
            onCancel={() => setShowSearch(false)}
            onSelect={(school) => {
              input.onChange(school)
              setShowSearch(false)
            }}
          />
        </Flex>
      </Modal>
      <PositionContainer position="relative">
        <Action
          onClick={() => setShowSearch(true)}
          type="button"
          as="div"
          data-cy={`edit-${name}`}
          aria-label="Edit"
        >
          <BasicRoundedTextField
            label={label}
            inputProps={{
              value: input.value ? input.value.name : '',
              type: 'text',
              readOnly: true,
              id: name,
              name: name,
            }}
            labelProps={{htmlFor: name}}
            linkify
          />
        </Action>
      </PositionContainer>
    </>
  )
}

export default SchoolField
