import {InputHTMLAttributes} from 'react'

import {animation, theme} from 'src/styles'
import {gradients} from 'src/styles/gradients'

import DisabledOverlay from './DisabledOverlay'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'checkbox'
}

const inputCss = {
  opacity: 0,
  width: 0,
  height: 0,
  ':checked + span': {
    background: gradients.toggleControl,
    borderColor: theme.colors.primary,
  },
  ':checked + span:before': {
    left: 25,
    backgroundColor: theme.colors.primary,
  },
}

const ToggleInput = (props: Props) => (
  <label
    css={{
      position: 'relative',
      display: 'inline-block',
      width: 55,
      height: 34,
    }}
  >
    <input type="checkbox" css={inputCss} {...props} />
    <span
      css={(theme) => ({
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: theme.colors.white,
        transition: animation.selection('all'),
        border: `3px solid ${theme.colors.grey5}`,
        borderRadius: 50,
        boxSizing: 'border-box',
        '&:before': {
          position: 'absolute',
          content: `""`,
          height: 20,
          width: 20,
          left: 4,
          bottom: 4,
          backgroundColor: theme.colors.grey5,
          transition: animation.selection('all'),
          borderRadius: 50,
        },
      })}
    />
    {props.disabled && (
      // Semitranparent overlay that covers button when disabled.
      <DisabledOverlay />
    )}
  </label>
)
export default ToggleInput
