import {forwardRef} from 'react'
import {useField, UseFieldConfig} from 'react-final-form'
import {FieldValidator} from 'final-form'

import {composeValidators, validMinMax} from 'src/form/validation'
import {identity} from 'src/form/parse'
import {PositionContainer} from 'src/components/Box'

import {hasFieldError, BasicTextField} from './TextField'
import {BasicRoundedTextField} from './RoundedTextField'
import {ErrorMessage} from './input'

export interface Props extends UseFieldConfig<string> {
  label: string
  name: string
  id?: string
  autoFocus?: boolean
  readOnly?: boolean

  type?: 'number' | 'tel'
  min?: number
  max?: number
  pattern?: string
  inputMode?: 'numeric' | 'tel' | 'decimal'

  TextFieldComponent?: typeof BasicTextField | typeof BasicRoundedTextField
}

const NumberField = forwardRef<HTMLInputElement, Props>(
  (
    {
      TextFieldComponent = BasicTextField,
      label,
      name,
      id,
      type = 'number',
      parse = identity,
      autoFocus = false,
      readOnly = false,
      min,
      max,
      pattern = '[0-9]*',
      inputMode = 'numeric',
      validate,
      ...config
    },
    ref
  ) => {
    const {input, meta} = useField<string, HTMLInputElement>(name, {
      parse,
      validate: composeValidators(
        validate,
        validMinMax(min, max)
      ) as FieldValidator<string>,
      ...config,
    })

    const inputId = id || name

    const showError = !readOnly && hasFieldError(meta)
    const shouldUseInlineError = TextFieldComponent === BasicRoundedTextField

    const errorMessage = showError ? (
      <ErrorMessage>{meta.error || meta.submitError}</ErrorMessage>
    ) : null

    return (
      <PositionContainer pb={shouldUseInlineError ? 0 : 3} position="relative">
        <TextFieldComponent
          ref={ref}
          error={showError}
          label={label}
          inputProps={{
            ...input,
            type,
            readOnly,
            autoFocus,
            pattern,
            inputMode,
            id: inputId,
          }}
          labelProps={{htmlFor: inputId}}
          errorMessage={shouldUseInlineError ? errorMessage : undefined}
        />
        {!shouldUseInlineError && errorMessage ? (
          <PositionContainer position="absolute" bottom="0" left="0">
            {errorMessage}
          </PositionContainer>
        ) : null}
      </PositionContainer>
    )
  }
)

export default NumberField
